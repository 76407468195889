<div class="service-item">
            
    <div class="service-cont">
        <h3 class="service-head">{{ heading }}</h3>
        
        <p class="service-text">{{ text }}</p>
         
    </div>
    <div class="service-photo-container">
        <img [src]="imageLink1" alt="heading">
        <img [src]="imageLink2" alt="heading" class="mobile-hide">
    </div>
    <div style="clear: both;"></div>
    

</div>