<app-page-heading [heading]="'O nás'"></app-page-heading>
<app-text-with-side-image
    [renderHeading]="true"
    [heading]="content.about[0].heading"
    [text]="content.about[0].text"
    [imageLink]="content.about[0].image"></app-text-with-side-image>
<app-divider></app-divider>
<app-text-with-side-image
    [renderHeading]="true"
    [heading]="content.about[1].heading"
    [text]="content.about[1].text"
    [imageLink]="content.about[1].image"></app-text-with-side-image>
